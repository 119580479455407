<template>
  <div>
    <v-row>
      <v-col class="text-right" cols="12">
        <v-btn
          @click="$router.push('/configuracoes/prontuario/novo')"
          bottom
          large
          color="primary"
          >Novo Modelo</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="professional_records.data"
      :loading="loading"
      :items-per-page="-1"
      @click:row="openAnamneseForm($event.id)"
      :server-items-length="professional_records.total"
      hide-default-footer
      disable-sort
    >
      <template v-slot:[`item.action`]="{ item }">
        <app-delete-btn @click="deleteItem(item.id)" type="icon" />
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-model="professional_records.current_page"
        :length="professional_records.last_page"
        @input="select($event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      professional_records: {},
      loading: false,
      headers: [
        { text: "Nome", align: "start", value: "name" },
        { text: "", align: "end", value: "action" },
      ],
    };
  },
  mounted() {
    this.select();
  },
  methods: {
    openAnamneseForm(professional_record_id) {
      this.$router.push(`/configuracoes/prontuario/${professional_record_id}`);
    },

    select(page = 1) {
      this.loading = true;
      this.$http
        .index("professional-record/professional-record", { page: page })
        .then((response) => {
          this.professional_records = response.professional_records;
          this.loading = false;
        });
    },

    deleteItem(professional_record_id) {
      this.$loading.start();
      this.$http
        .destroy(
          "professional-record/professional-record",
          professional_record_id
        )
        .then((response) => {
          this.$loading.finish();
          this.select();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>